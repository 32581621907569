import React from "react";
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import { useIntl } from 'gatsby-plugin-intl';

import TitleSection from "../../objects/TitleSection"

import { HistorySection, 
         HistoryContainer,
         Image, 
         History, 
         Title, 
         Description } from "./styles"

const CompanyHistory = ()  => {

   const intl = useIntl();

   const content = useStaticQuery (
      graphql`
         query {
            imgAbout: file(relativePath: { eq: "img-historia-Eletrosil.jpg" }) {
               childImageSharp {
                  fluid(maxWidth: 600) {
                     ...GatsbyImageSharpFluid_tracedSVG
                  }
               }
            }
         }
      `
   )

   return (
      <HistorySection>
         <TitleSection tagAs="h1"
            title={intl.formatMessage({ id: "companypage.titlepage" })} 
         />
         <HistoryContainer>
            <Image>
               <Img 
                  fluid={content.imgAbout.childImageSharp.fluid}
                  alt="imagem de ampulheta marcando o tempo" 
               />
            </Image>
            <History>
               <Title>
                  {intl.formatMessage({ id: "companypage.historytitle" })}
               </Title>
               <Description>
                  {intl.formatMessage({ id: "companypage.historytext1" })}
               </Description>
               <Description>
                  {intl.formatMessage({ id: "companypage.historytext2" })}
               </Description>
               <Description>
                  {intl.formatMessage({ id: "companypage.historytext3" })}
               </Description>
            </History>
         </HistoryContainer>
      </HistorySection>
  )
}

export default CompanyHistory