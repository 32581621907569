import styled from "styled-components";
import media from "../../styles/breakPoints"

import Section from "../../objects/Section"
import Container from "../../objects/Container"

const QualitySection = styled(Section)`
   background-color: ${props => props.theme.color.grayClear};
   flex-direction: column;
   padding: 50px 0;
`

const QualityContainer = styled(Container)`
   flex-direction: column;
   padding: 30px 15px 0;
`

const Description = styled.p`
   width: 100%;
   max-width: 992px;
   font-family: ${props => props.theme.font.secondy};
   color: ${props => props.theme.color.grayMedium};
   font-size: ${props => props.theme.size.verySmall};
   margin: 0 15px 20px;
   &.-centered {
      text-align: center;
   }

   ${media.greaterThan("md")`
      font-size: ${props => props.theme.size.small};
      margin: 0 auto 30px;
   `}

`

const ActionArea = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   width: 100%;
   height: 100px;
   text-align: center;

   ${media.greaterThan("md")`
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
   `}

`

const ButtonDownload = styled.a`
   display: inline-block;
   margin: 0 auto;
   background-color: ${props => props.theme.color.primaryDark};
   color: ${props => props.theme.color.grayClear};
   padding: 7px 12px;
   border-radius: 5px;
   border: none;
   text-transform: uppercase;
   font-family: ${props => props.theme.font.secondy};
   font-size: ${props => props.theme.size.verySmall};
   text-align: center;
   font-weight: bold;
   text-decoration: none;
   cursor: pointer;
   transition: all 300ms linear;
   opacity: 0.9;
   &:hover {
      transition: all 300ms linear;
      text-decoration: inherit;
      opacity: 1;
   }

   ${media.greaterThan("sm")`
      font-size: ${props => props.theme.size.small};
   `}

   ${media.greaterThan("lg")`
      font-size: ${props => props.theme.size.mediumSmall};
      padding: 10px 20px;
   `}
`

export {
   QualitySection,
   QualityContainer,
   Description,
   ActionArea,
   ButtonDownload
}