import styled from "styled-components";
import media from "../../styles/breakPoints"

import Section from "../../objects/Section"
import Container from "../../objects/Container"

const HistorySection = styled(Section)`
   background-color: ${props => props.theme.color.grayLight};
   flex-direction: column;
   padding: 50px 0;
`

const HistoryContainer = styled(Container)`
   padding-top: 30px;

   ${media.greaterThan("md")`
      padding-top: 50px;
   `}

`

const Image = styled.div`
   display: none;
   width: 50%;
   margin: 0;

   ${media.greaterThan("lg")`
      display: block;
      margin: 0 -100px 0 30px;
      padding: 0;
      & > img {
         width: 100%;
         height: 100%;
         max-height: 240px;
      }
   `}

`

const History = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   background-color: ${props => props.theme.color.grayClear};
   margin: 0 20px;
   padding: 20px;
   z-index: 2;

   ${media.greaterThan("md")`
      margin: 40px 25px;
      padding: 30px;
   `}

   ${media.greaterThan("lg")`
      width: 50%;
      padding: 40px;
   `}

`

const Title = styled.h3`
   font-family: ${props => props.theme.font.primary};
   font-size: ${props => props.theme.size.small};
   font-weight: bold;
   color: ${props => props.theme.color.grayMedium};
   text-align: left;
   margin-bottom: 20px;
   &::after {
      content: "";
      display: block;
      width: 50px;
      height: 4px;
      background: ${props => props.theme.color.primaryDark};
      margin: 10px 0;
   }

   ${media.greaterThan("md")`
      font-size: ${props => props.theme.size.mediumSmall};
   `}

`

const Description = styled.p`
   font-family: ${props => props.theme.font.primary};
   color: ${props => props.theme.color.grayMedium};
   font-size: ${props => props.theme.size.verySmall};
   margin: 0;
   &:not(:last-child) {
      margin-bottom: 16px;
   }
`

export {
   HistorySection,
   HistoryContainer,
   Image,
   History,
   Title,
   Description
}