import React from "react";
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl';

import Layout from "../components/Layout";
import SEO from "../components/seo"

import CompanyHistory from "../components/CompanyHistory"
import CompanyHeadquarters from "../components/CompanyHeadquarters"
import CompanyQuality from "../components/CompanyQuality"

const Empresa = () => {

   const intl = useIntl();

   const content = useStaticQuery(
      graphql`
         query {
            imgHeadquarters
            : allFile (
               filter: {relativeDirectory: { eq: "headquarters-carousel" }},
               sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
               node {
                  childImageSharp {
                     fixed(width: 400) {
                     ...GatsbyImageSharpFixed
                     }
                  }
               }
               }
            }
         }
      `
    )
  
   return (
      <Layout>
         <SEO 
            lang={intl.locale}
            title={intl.formatMessage({ id: "navbar.item2" })}
            keywords={[ `indústria metalúrgica em São Paulo`, `política da qualidade`, `certificação ISO 9001`, `padrão de qualidade` ]}
         />
         <CompanyHistory />
         <CompanyHeadquarters
            element="glide-headquarters" 
            children={content.imgHeadquarters.edges}
         />
         <CompanyQuality />
      </Layout>
   )
}

export default Empresa