import styled from "styled-components";
import media from "../../styles/breakPoints"

import Section from "../../objects/Section"
import Container from "../../objects/Container"

const HeadquartersSection = styled(Section)`
   background-color: ${props => props.theme.color.secondyLight};
   flex-direction: column;
   padding: 50px 0;
`

const HeadquartersContainer = styled(Container)`
   padding-top: 30px;
`

const Description = styled.p`
   width: 100%;
   max-width: 992px;
   font-family: ${props => props.theme.font.secondy};
   color: ${props => props.theme.color.grayMedium};
   font-size: ${props => props.theme.size.verySmall};
   margin: 0 15px 30px;

   ${media.greaterThan("md")`
      font-size: ${props => props.theme.size.small};
      margin: 0 auto 30px;
   `}
`

// GLIDE CAROUSEL

const WrapGlide = styled.div`
   width: 100vw;
   max-width: 992px;
   display: block;
   height: auto;
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   & * {
      box-sizing: inherit; 
   }
   &.glide--rtl {
      direction: rtl; 
   }
`

const GlideTrack = styled.div`
   position: relative;
   overflow: hidden;
   padding-bottom: 2rem;
`
 
const GlideSlides = styled.ul`
   position: relative;
   width: 100vw;
   list-style: none;
   backface-visibility: hidden;
   transform-style: preserve-3d;
   touch-action: pan-Y;
   overflow: hidden;
   white-space: nowrap;
   display: flex;
   flex-wrap: nowrap;
   margin: 0;
   padding: 0;
   will-change: transform;
   box-sizing: border-box;
   &.--dragging {
      user-select: none; 
   }
`

const GlideSlide = styled.li`
position: relative;
   width: 100%;
   height: 100%;
   flex-shrink: 0;
   white-space: normal;
   user-select: none;
   -webkit-touch-callout: none;
   -webkit-tap-highlight-color: transparent;
   margin: 0;
   text-align: center;
   & a {
      user-select: none;
      -webkit-user-drag: none;
      -moz-user-select: none;
      -ms-user-select: none; 
      margin: 0;
   }
`

const Legend = styled.small`
   position: absolute;
   left: 50%;
   bottom: 4px;
   transform: translateX(-50%);
   width: 320px;
   height: auto;
   font-family: ${props => props.theme.font.secondy};
   color: ${props => props.theme.color.grayClear};
   font-size: ${props => props.theme.size.verySmall};
   background-color: rgba(0,0,0,0.7);
   padding: 4px 24px;
`

const GlideBullets = styled.div`
   position: absolute;
   width: 100%;
   display: flex;
   bottom: 0;
   justify-content: center;
`

const GlideBullet = styled.button`
   display: none;
   width: 8px;
   height: 8px;
   border: none;
   background-color: ${props => props.theme.color.primaryBase};
   opacity: 0.5;
   border-radius: 50%;
   margin: 0 5px;
   outline: none;
   padding: 5px;
   cursor: pointer;
   &:hover,
   &.glide__bullet--active {
      border: none;
      opacity: 1;
   }

   ${media.greaterThan("sm")`
      display: flex;
   `}
`
 
export {
   HeadquartersSection,
   HeadquartersContainer,
   Description,
   WrapGlide,
   GlideTrack,
   GlideSlides,
   GlideSlide,
   Legend,
   GlideBullets,
   GlideBullet
}