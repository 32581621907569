import React, { useEffect } from "react";
import Glide from "@glidejs/glide"
import Img from "gatsby-image"
import { useIntl } from 'gatsby-plugin-intl';

import TitleSection from "../../objects/TitleSection"

import { HeadquartersSection, 
         HeadquartersContainer,
         Description,
         WrapGlide,
         GlideTrack,
         GlideSlides,
         GlideSlide,
         Legend,
         GlideBullets,
         GlideBullet } from "./styles"

const options = {
   type: "carousel",
   gap: 10,
   startAt: 0,
   perView: 3,
   autoplay: 5000,
   hoverpause: true,
   keyboard: true,
   animationDuration: 1000,
   breakpoints: {
      992: { perView: 2 },
      768: { perView: 1 },
   }
}

const CompanyHeadquarters = ({ element = "glide", children }) => {

   const intl = useIntl();
   const slider = new Glide(`.${element}`, options)
   
   useEffect(() => {
      slider.mount()
   }, [slider])

   return (
      <HeadquartersSection>
         <TitleSection 
            title={intl.formatMessage({ id: "companypage.headquarterstitle" })}
         />
         <HeadquartersContainer>
            <Description>
               {intl.formatMessage({ id: "companypage.headquarterstext" })}
            </Description>
         </HeadquartersContainer>
         <WrapGlide className={element}>
            <GlideTrack data-glide-el="track">
               <GlideSlides>
                  {children.map((slide, index) => (
                     <GlideSlide key={index} className="slider">
                        <Img 
                           fixed={slide.node.childImageSharp.fixed}
                           alt={intl.formatMessage({ id: `companypage.images.alts.alt_${index + 1}` })}
                           style={{ width: "320px", height: "214px" }}
                        />
                        <Legend>
                           {intl.formatMessage({ id: `companypage.images.legends.legend_${index + 1}` })}
                        </Legend>
                     </GlideSlide>
                  ))}
               </GlideSlides>
               <GlideBullets data-glide-el="controls[nav]">
                  {children.map((slide, index) => (
                     <GlideBullet key={index} data-glide-dir={`=${index}`}/>
                  ))}
               </GlideBullets>
            </GlideTrack>
         </WrapGlide>
      </HeadquartersSection>
   )
};

export default CompanyHeadquarters
