import React from "react";
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl';

import TitleSection from "../../objects/TitleSection"

import { QualitySection, 
         QualityContainer,
         Description,
         ActionArea,
         ButtonDownload } from "./styles"

const CompanyQuality = () => {

   const intl = useIntl();

   const content = useStaticQuery (
      graphql`
         query {
            QualityPDF: file(relativePath: { 
               eq: "others/Politica_da_Qualidade_Eletrosil_rev03.pdf" }) {
                  publicURL
            }
            CertificationPDFpt: file(relativePath: { 
               eq: "others/pt/Certif_ISO9001-Eletrosil-valid_10-2025.pdf" }) {
                  publicURL
            }
            CertificationPDFes: file(relativePath: { 
               eq: "others/es/Certif_ISO9001-Eletrosil-valid_10-2025.pdf" }) {
                  publicURL
            }
            CertificationPDFen: file(relativePath: { 
               eq: "others/en/Certif_ISO9001-Eletrosil-valid_10-2025.pdf" }) {
                  publicURL
            }
         }
      `
   )

   const ButtonDownloadTarget = (lang) => {
      if (lang === "pt") {
         return (
            <ButtonDownload 
               href={content.CertificationPDFpt.publicURL}
               rel="noopener"
               target="_blank">
               {intl.formatMessage({ id: "companypage.qualitypolicylabel2" })}
            </ButtonDownload>      
         )
      } else if (lang === "es") {
         return (
            <ButtonDownload 
               href={content.CertificationPDFes.publicURL}
               rel="noopener"
               target="_blank">
               {intl.formatMessage({ id: "companypage.qualitypolicylabel2" })}
            </ButtonDownload>      
         )
      } else {
         return (
            <ButtonDownload 
               href={content.CertificationPDFen.publicURL}
               rel="noopener"
               target="_blank">
               {intl.formatMessage({ id: "companypage.qualitypolicylabel2" })}
            </ButtonDownload>      
         )
      }
   }

   return (
      <QualitySection>
         <TitleSection 
            title={intl.formatMessage({ id: "companypage.qualitypolicytitle" })} 
         />
         <QualityContainer>
            <Description>
               {intl.formatMessage({ id: "companypage.qualitypolicytext1" })}
            </Description>
            <Description className="-centered">
               {intl.formatMessage({ id: "companypage.qualitypolicytext2" })}
            </Description>
            <ActionArea>
               <ButtonDownload 
                  href={content.QualityPDF.publicURL}
                  rel="noopener"
                  target="_blank">
                  {intl.formatMessage({ id: "companypage.qualitypolicylabel1" })}
               </ButtonDownload> 
               {ButtonDownloadTarget(intl.locale)} 
            </ActionArea>
         </QualityContainer>
      </QualitySection>
   )
}

export default CompanyQuality

